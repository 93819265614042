interface IProps {
    className?: string;
    noText?: boolean;
}

const Icon: React.FC<IProps> = ({ className, noText }) => {
    return (
        <div className={className}>
            <img src="./pancake.png" height={18} className="m-0" />
            {!noText && (
                <svg viewBox="0 0 181 34" xmlns="http://www.w3.org/2000/svg" className="icon-svg">
                    <text className="icon-text" x="5" y="23" transform="matrix(1.023067, 0, 0, 1, -0.855269, 0)">
                        Pancake
                    </text>
                </svg>
            )}
        </div>
    );
};
export default Icon;
