import { useEffect, useRef, useState, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import flatten from "lodash/flatten";
import ExchangeCell from "./ExchangeCell";

import { TableData } from "components/Share";
import { saveReserveAddress } from "actions/signActions";
import LoadingIndicator from "components/_base/LoadingIndicator";

import { IOrder } from "./model";
import Filter from "./Filter";
import TransactionHashCell from "./TransactionHashCell";
import { getTradelogs, getMultiOrdersTradelogs, getTokenRate } from "../../services/configuration";
import TimestampCell from "./TimestampCell";
import Select, { MultiValue } from "react-select";
import "./highlight.css";
import useLocalStorage from "hook/useLocalStorageTs";

import { concat } from "lodash";

const DEFAULT_LIMIT = 1000;
const hftAddress = "0x9a5ef5f053dd2efaff0b74e1876c66f6825774af";
const hftAddressV3 = "0x24b9d98fabf4da1f69ee10775f240ae3da6856fd";
const vtAddress = "0x1a847b0d11120b8510edcd3c81c4e4249460330a";

interface IProps {
    className?: string;
}

export interface IOption {
    value: string;
    label: string;
}

const capitalize = (s: string) => {
    return s.charAt(0).toUpperCase() + s.slice(1);
};

const usdDiff = (d: IOrder, rateETH: number, reserveAddress: string) => {
    let maker_usd = 0;
    if (Array.isArray(d.maker_usd_t30)) maker_usd = d.maker_usd_t30.reduce((s, a) => (s += a), 0);
    else maker_usd = d.maker_usd_t30;

    let taker_usd = 0;
    if (Array.isArray(d.taker_usd_t30)) taker_usd = d.taker_usd_t30.reduce((s, a) => (s += a), 0);
    else taker_usd = d.taker_usd_t30;

    let fee = 0;
    if (d.fee_amt_converted) {
        if (d.fee_token_converted == "ETH") fee = d.fee_amt_converted * rateETH;
        if (d.fee_token_converted == "USDC") fee = d.fee_amt_converted;
    }
    if (isMaker(d.maker, reserveAddress)) return (maker_usd || 0) - (taker_usd || 0) - fee;
    return (taker_usd || 0) - (maker_usd || 0) - fee;
};

const isMaker = (marker: string, reserveAddress: string) => {
    return ![
        reserveAddress.toLowerCase(),
        hftAddress.toLowerCase(),
        hftAddressV3.toLowerCase(),
        vtAddress.toLowerCase(),
    ].includes(marker.toLowerCase());
};

const profitBps = (d: IOrder, rateETH: number, reserveAddress: string) => {
    let maker_usd = 0;
    if (Array.isArray(d.maker_usd_t30)) maker_usd = d.maker_usd_t30.reduce((s, a) => (s += a), 0);
    else maker_usd = d.maker_usd_t30;

    let taker_usd = 0;
    if (Array.isArray(d.taker_usd_t30)) taker_usd = d.taker_usd_t30.reduce((s, a) => (s += a), 0);
    else taker_usd = d.taker_usd_t30;

    const diff = usdDiff(d, rateETH, reserveAddress);
    let outputAmt = maker_usd;
    if (isMaker(d.maker, reserveAddress)) outputAmt = taker_usd;

    return outputAmt > 5e-3 ? (diff / outputAmt) * 1e4 : 0;
};

const fromTimeKey = "tradeLogs_fromTime_filter";
const toTimeKey = "tradeLogs_toTime_filter";

const displayNumberValue = (val: number | number[], fixed: number, parseNumber = false) => {
    if (!val) return "";

    if (Array.isArray(val)) {
        return (
            <span defaultValue={val[0] || 0}>
                {val.map((s: number, k: number) => (
                    <span key={k}>
                        {s?.toFixed(fixed) || ""}
                        <br className={val.length == k + 1 ? "d-none" : ""} />
                    </span>
                ))}
            </span>
        );
    }

    return parseNumber ? parseFloat(val?.toFixed(fixed) || "0") : val?.toFixed(fixed) || "0";
};

const displayStringValue = (val: string | string[]) => {
    if (Array.isArray(val)) {
        return (
            <span>
                {val.map((s: string, k: number) => (
                    <span key={k}>
                        {s}
                        <br className={val.length == k + 1 ? "d-none" : ""} />
                    </span>
                ))}
            </span>
        );
    }

    return val;
};

const GroupOrders = (orders: IOrder[], rateETH: number) => {
    const mappedTxOrds = new Map<string, IOrder[]>();

    orders.forEach((o) => {
        let ords = mappedTxOrds.get(o.tx_hash);
        if (!ords) ords = [];
        ords.push(o);
        mappedTxOrds.set(o.tx_hash, ords);
    });

    const ret: IOrder[] = [];
    mappedTxOrds.forEach((value, key) => {
        if (value.length == 1) ret.push(value[0]);
        else if (value.length > 1) {
            // sort it by input usd
            value.sort((x, y) => {
                let xU = 0,
                    yU = 0;
                if (Array.isArray(x.taker_usd)) xU = Math.max(...x.taker_usd);
                else xU = x.taker_usd;

                if (Array.isArray(y.taker_usd)) yU = Math.max(...y.taker_usd);
                else yU = y.taker_usd;

                return xU < yU ? 1 : -1;
            });
            let maker_amount: number[] = [];
            let maker_token: string[] = [];
            let maker_usd: number[] = [];
            let taker_amount: number[] = [];
            let taker_token: string[] = [];
            let taker_usd: number[] = [];
            let maker_usd_t30: number[] = [];
            let taker_usd_t30: number[] = [];

            const exchangeSet = new Set<string>();
            let exchanges = "";

            const feeTokenSet = new Set<string>();
            let totalFeeAmt = 0;

            value.forEach((o: IOrder) => {
                if (Array.isArray(o.maker_amount)) maker_amount = concat(maker_amount, o.maker_amount);
                else maker_amount.push(o.maker_amount);

                if (Array.isArray(o.maker_token)) maker_token = concat(maker_token, o.maker_token);
                else maker_token.push(o.maker_token);

                if (Array.isArray(o.maker_usd)) maker_usd = concat(maker_usd, o.maker_usd);
                else maker_usd.push(o.maker_usd);

                if (Array.isArray(o.taker_amount)) taker_amount = concat(taker_amount, o.taker_amount);
                else taker_amount.push(o.taker_amount);

                if (Array.isArray(o.taker_token)) taker_token = concat(taker_token, o.taker_token);
                else taker_token.push(o.taker_token);

                if (Array.isArray(o.taker_usd)) taker_usd = concat(taker_usd, o.taker_usd);
                else taker_usd.push(o.taker_usd);

                if (Array.isArray(o.maker_usd_t30)) maker_usd_t30 = concat(maker_usd_t30, o.maker_usd_t30);
                else maker_usd_t30.push(o.maker_usd_t30);

                if (Array.isArray(o.taker_usd_t30)) taker_usd_t30 = concat(taker_usd_t30, o.taker_usd_t30);
                else taker_usd_t30.push(o.taker_usd_t30);

                exchangeSet.add(o.exchange);
                if (o.fee_token_converted) feeTokenSet.add(o.fee_token_converted);
                if (o.fee_amt_converted) totalFeeAmt += o.fee_amt_converted;
            });

            exchanges = Array.from(exchangeSet).join(",");

            let fee_amt_converted = 0,
                fee_token_converted = "";

            if (exchanges == "kyberswap") {
                fee_amt_converted = value[0].fee_amt_converted;
                fee_token_converted = value[0].fee_token_converted;
            } else {
                if (feeTokenSet.size == 1) {
                    fee_amt_converted = totalFeeAmt;
                    fee_token_converted = feeTokenSet.values().next().value || "";
                }

                if (feeTokenSet.size > 1) {
                    // convert them to USDC
                    value.forEach((o) => {
                        if (o.fee_token_converted == "ETH") fee_amt_converted += o.fee_amt_converted * rateETH;
                        else if (o.fee_amt_converted) fee_amt_converted += o.fee_amt_converted;
                    });
                    fee_token_converted = "USDC";
                }
            }

            ret.push({
                time: value[0].time,
                maker: value[0].maker,
                maker_amount,
                maker_token,
                maker_usd,
                taker_amount,
                taker_token,
                taker_usd,
                exchange: exchanges,
                tx_hash: key,
                fee_amt_converted,
                fee_token_converted,
                maker_usd_t30,
                taker_usd_t30,
            });
        }
    });
    return ret;
};

function haveCommonChild(arr1: string[], arr2: string[]): boolean {
    const set1 = new Set(arr1);
    return arr2.some((item) => set1.has(item));
}

const TradeLog: React.FC<IProps> = ({ className }) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const mountedRef = useRef(false);

    const dtp = localStorage.getItem("dateTimePickerLastUpdate");
    // 3 min to reset filter cache
    if (!dtp || Date.now() - parseInt(dtp) > 180_000) {
        localStorage.removeItem(fromTimeKey);
        localStorage.removeItem(toTimeKey);
    }

    // in seconds
    const [toTime, setToTime] = useLocalStorage(
        toTimeKey,
        parseInt(localStorage.getItem(toTimeKey) || "") || Math.round(Date.now() / 1000)
    );
    const [fromTime, setFromTime] = useLocalStorage(
        fromTimeKey,
        parseInt(localStorage.getItem(fromTimeKey) || "") || toTime - 24 * 60 * 60
    );
    const [address, setAddress] = useLocalStorage("tradeLogs_address_filter", "");

    const [data, setData] = useState<IOrder[]>([]);
    const [minUSD, setMinUSD] = useLocalStorage("tradeLogs_minUSD_filter", 0);
    const [rateETH, setETHRate] = useState(2000);
    const [integrations, setIntegrations] = useLocalStorage<MultiValue<IOption>>("tradeLogs_integration_filter", []);
    const [dexOptions, setDexOptions] = useState<IOption[]>([]);
    const [tokens, setTokens] = useLocalStorage<MultiValue<IOption>>("tradeLogs_token_filter", []);
    const [tokenOptions, setTokenOptions] = useState<IOption[]>([]);
    // @ts-ignore
    const reserveAddress = useSelector((state) => state.global.reserveAddr);
    // @ts-ignore
    const apiService = useSelector((state) => state.global.apiService);
    const [explorerURL, setExplorerURL] = useLocalStorage("explorerURL", "https://etherscan.io/tx/");

    const localFilterData = () => {
        let temp = [...data];
        if (minUSD > 0) {
            temp = temp.filter((d) => {
                let m = false;
                if (Array.isArray(d.maker_usd_t30)) m = Math.max(...d.maker_usd_t30) > minUSD;
                else m = d.maker_usd_t30 > minUSD;

                const t = false;
                if (Array.isArray(d.taker_usd_t30)) m = Math.max(...d.taker_usd_t30) > minUSD;
                else m = d.taker_usd_t30 > minUSD;
                return m || t;
            });
        }

        if (integrations?.length) {
            temp = temp.filter((d) =>
                haveCommonChild(
                    integrations.map((x) => x.value),
                    d.exchange.split(",")
                )
            );
        }

        if (tokens?.length) {
            const tokenList = tokens.map((x) => x.value);
            temp = temp.filter((d) => {
                let m = false;
                if (Array.isArray(d.maker_token)) m = haveCommonChild(tokenList, d.maker_token);
                else m = tokenList.includes(d.maker_token);

                const t = false;

                if (Array.isArray(d.taker_token)) m = haveCommonChild(tokenList, d.taker_token);
                else m = tokenList.includes(d.taker_token);
                return m || t;
            });
        }
        return temp;
    };

    useEffect(() => {
        const fetchAddressFromCore = async () => {
            const data = await apiService.getAddress();

            if (data?.data) {
                const addressesData = data.data.addresses;
                dispatch(saveReserveAddress(addressesData.reserve));
            }
        };

        if (!mountedRef.current && !reserveAddress && !address) {
            fetchAddressFromCore();
        }
    }, [address, apiService, dispatch, reserveAddress]);

    useEffect(() => {
        setToTime(parseInt(localStorage.getItem(toTimeKey) || "") || Math.round(Date.now() / 1000));
    }, [useLocation().key]);

    useEffect(() => {
        const addr = address || [reserveAddress, hftAddress, hftAddressV3, vtAddress].join(",");

        const fetchOrders = async () => {
            let offset = 0;
            const orders = [];
            while (offset > -1) {
                const result = await getTradelogs(
                    apiService,
                    addr,
                    fromTime * 1000,
                    toTime * 1000,
                    DEFAULT_LIMIT,
                    offset
                );
                const res = result.data ?? [];
                orders.push(...res);
                if (res.length < DEFAULT_LIMIT) {
                    return orders;
                } else {
                    offset += res.length;
                }
            }
        };

        const fetchMultiOrders = async () => {
            let offset = 0;
            const orders = [];
            while (offset > -1) {
                const result = await getMultiOrdersTradelogs(
                    apiService,
                    addr,
                    fromTime * 1000,
                    toTime * 1000,
                    DEFAULT_LIMIT,
                    offset
                );
                const res = result.data ?? [];
                orders.push(...res);
                if (res.length < DEFAULT_LIMIT) {
                    return orders;
                } else {
                    offset += res.length;
                }
            }
        };

        const fetch = async () => {
            setLoading(true);
            const rateRes = await getTokenRate(apiService, "ETH", "USDT");
            if (rateRes.success) {
                setETHRate(rateRes.data.rate);
            }

            let results = await fetchOrders();
            const multiOrdsRes = await fetchMultiOrders();
            results = results?.concat(multiOrdsRes);

            const tokens = new Set<string>();
            const exchanges = new Set<string>();

            results = GroupOrders(flatten(results), rateRes.data.rate);

            results?.sort((a, b: IOrder) => {
                return a.time > b.time ? -1 : 1;
            });

            results?.forEach((trade: IOrder) => {
                if (Array.isArray(trade.maker_token)) trade.maker_token.forEach((t) => tokens.add(t));
                else tokens.add(trade.maker_token);

                if (Array.isArray(trade.taker_token)) trade.taker_token.forEach((t) => tokens.add(t));
                else tokens.add(trade.taker_token);

                trade.exchange.split(",").forEach((ex) => {
                    exchanges.add(ex);
                });
            });

            const tokenOptions: IOption[] = [];
            tokens.forEach((s: string) => {
                tokenOptions.push({ value: s, label: s });
            });
            tokenOptions.sort((a, b: IOption) => {
                return a.label > b.value ? 1 : -1;
            });
            setTokenOptions(tokenOptions);

            const dexOptions: IOption[] = [];
            exchanges.forEach((s: string) => {
                dexOptions.push({ value: s, label: capitalize(s) });
            });
            dexOptions.sort((a, b: IOption) => {
                return a.label > b.label ? 1 : -1;
            });
            setDexOptions(dexOptions);

            setData(flatten(results));
            setLoading(false);
        };

        if (reserveAddress) {
            fetch();
        }
    }, [address, fromTime, reserveAddress, toTime]);

    useEffect(() => {
        mountedRef.current = true;

        return () => {
            mountedRef.current = false;
        };
    }, []);

    const standard = (x: any) => {
        switch (typeof x) {
            case "number":
                return x;
            case "object":
                return x.props.defaultValue;
            default:
                return 0;
        }
    };
    const numberSort = (x: any, y: any) => {
        return standard(x) > standard(y) ? 1 : -1;
    };
    const COLUMNS = [
        {
            Header: "When",
            id: "time",
            minWidth: 280,
            accessor: "time",
            Cell: TimestampCell,
        },
        {
            Header: "Input Amount",
            id: "input_amount",
            accessor: (d: IOrder) => {
                if (isMaker(d.maker, reserveAddress)) return displayNumberValue(d.maker_amount, 5);
                return displayNumberValue(d.taker_amount, 5);
            },
            minWidth: 170,
            sortable: false,
        },
        {
            Header: "Input Token",
            id: "Input_token",
            accessor: (d: IOrder) => {
                if (isMaker(d.maker, reserveAddress)) return displayStringValue(d.maker_token);
                return displayStringValue(d.taker_token);
            },
            minWidth: 80,
        },
        {
            Header: "Output Amount",
            id: "output amount",
            accessor: (d: IOrder) => {
                if (isMaker(d.maker, reserveAddress)) return displayNumberValue(d.taker_amount, 5);
                return displayNumberValue(d.maker_amount, 5);
            },
            minWidth: 170,
            sortable: false,
        },
        {
            Header: "Output Token",
            id: "output_token",
            accessor: (d: IOrder) => {
                if (isMaker(d.maker, reserveAddress)) return displayStringValue(d.taker_token);
                return displayStringValue(d.maker_token);
            },
            minWidth: 80,
        },
        {
            Header: "Input USD",
            id: "input_usd",
            accessor: (d: IOrder) => {
                if (isMaker(d.maker, reserveAddress)) return displayNumberValue(d.maker_usd_t30, 2, true);
                return displayNumberValue(d.taker_usd_t30, 2, true);
            },
            minWidth: 100,
            sortMethod: numberSort,
        },
        {
            Header: "Output USD",
            id: "maker_usd_t30",
            accessor: (d: IOrder) => {
                if (isMaker(d.maker, reserveAddress)) return displayNumberValue(d.taker_usd_t30, 2, true);
                return displayNumberValue(d.maker_usd_t30, 2, true);
            },
            minWidth: 100,
            sortMethod: numberSort,
        },
        {
            Header: "Fee",
            id: "fee",
            accessor: "fee_amt_converted",
            Cell: ({ original }: any) => {
                const d = original;
                // adhoc for kyberswapRFQ, return 0 fee
                if (d.exchange == "kyberswapRFQ") return "";
                if (d.fee_amt_converted) {
                    if (d.fee_token_converted == "USDC")
                        return `${d.fee_amt_converted.toFixed(2)} ${d.fee_token_converted}`;
                    return `${d.fee_amt_converted.toFixed(5)} ${d.fee_token_converted}`;
                }
                return "";
            },
            minWidth: 120,
        },
        {
            Header: "USD Diff",
            id: "usd_diff",
            accessor: (d: IOrder) => {
                return parseFloat(usdDiff(d, rateETH, reserveAddress).toFixed(2));
            },
            minWidth: 100,
        },
        {
            Header: "Profit bps",
            id: "profit_bps",
            accessor: (d: IOrder) => {
                return parseFloat(profitBps(d, rateETH, reserveAddress).toFixed(1));
            },
            minWidth: 100,
        },
        {
            Header: "Transaction",
            id: "transaction",
            accessor: "tx_hash",
            Cell: (props: any) => {
                return <TransactionHashCell value={props.value} className={props.className} explorer={explorerURL} />;
            },
            sortable: false,
            minWidth: 200,
        },
        {
            Header: "exchange",
            id: "exchange",
            accessor: "exchange",
            Cell: ExchangeCell,
            className: "text-left",
        },
    ];

    const rowClassName = (state: any, rowInfo: any) => {
        switch (true) {
            case rowInfo.row.usd_diff < -100:
                return "large-negative-usd";
            case rowInfo.row.usd_diff < -50:
                return "medium-negative-usd";
            case rowInfo.row.usd_diff < -10:
                return "small-negative-usd";
            case rowInfo.row.usd_diff > 5000:
                return "gigantic-positive-usd";
            case rowInfo.row.usd_diff > 1000:
                return "huge-positive-usd";
            case rowInfo.row.usd_diff > 100:
                return "large-positive-usd";
            case rowInfo.row.usd_diff > 50:
                return "medium-positive-usd";
            case rowInfo.row.usd_diff > 10:
                return "small-positive-usd";
            default:
                return "bg-white";
        }
    };

    const explorerOptions = [
        { value: "https://etherscan.io/tx/", label: "Etherscan" },
        { value: "https://app.blocksec.com/explorer/tx/eth/", label: "Blocksec" },
        { value: "https://metasleuth.io/result/eth/", label: "Metasleuth" },
    ];

    const componentLeftToPageSize = (
        <div className="d-inline-block ml-4 mobile-mb-s">
            <div className="d-inline-block mr-2 text-gray-4">EXPLORER</div>

            <div className="d-inline-block" style={{ minWidth: "125px" }}>
                <Select
                    className="w-100"
                    options={explorerOptions}
                    value={explorerOptions.find((e) => e.value == explorerURL)}
                    onChange={(e) => setExplorerURL(e?.value || "")}
                />
            </div>
        </div>
    );

    return (
        <div className={className}>
            <h4 className="pageTitle px-0 py-4">Trade Log</h4>
            <Filter
                fromTime={fromTime}
                toTime={toTime}
                onFromTimeChange={setFromTime}
                onToTimeChange={setToTime}
                address={address}
                onAddressChange={setAddress}
                defaultAddress={`${reserveAddress},${hftAddress},${hftAddressV3},${vtAddress}`}
                minUSD={minUSD}
                setMinUSD={setMinUSD}
                dexOptions={dexOptions}
                integrations={integrations}
                setIntegrations={setIntegrations}
                tokenOptions={tokenOptions}
                tokens={tokens}
                setTokens={setTokens}
            />

            <TableData
                id="tradeLogTbl"
                isShowPaginate
                isShowPageSize
                initialPageSize={200}
                isShowPaginateTop
                data={loading ? [] : localFilterData()}
                columns={COLUMNS}
                rowClassName={rowClassName}
                componentLeftToPageSize={componentLeftToPageSize}
            />

            {loading ? (
                <div className="loadingText">
                    <span className="text">Loading</span> <LoadingIndicator size="m" />
                </div>
            ) : null}
        </div>
    );
};

export default styled(TradeLog)`
    width: 100%;
    padding: 1vh 2vw;

    .pageTitle {
        color: #141927;
    }

    .loadingText {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 0.5em;

        .text {
            font-size: 16px;
        }
    }

    .rfq {
        font-size: 8px;
        position: absolute;
        top: -3px;
        left: -10px;
    }

    .v3 {
        font-size: 8px;
        position: absolute;
        top: -5px;
        left: -12px;
    }

    .one-inch-v6 {
        font-size: 9px;
        position: absolute;
        left: -3px;
    }

    .uniswapx {
        color: #222;
        font-size: 11px;
        font-weight: 100;
        position: absolute;
        top: 7px;
        left: 4px;
    }

    .pcsx {
        color: #222;
        font-size: 11px;
        position: absolute;
        top: 4px;
        left: 7px;
    }

    .bebop {
        color: #222;
        font-weight: 600;
        font-size: 12px;
        position: absolute;
        top: 6px;
        left: 4px;
    }

    .zeroxv3 {
        font-size: 8px;
        position: absolute;
        top: -5px;
        left: -42px;
    }
`;
