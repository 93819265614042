import { IOrder } from "./model";

import Bebop from "./icon/Bebop";
import Cow from "./icon/Cow";
import Hashflow from "./icon/Hashflow";
import Ksw from "./icon/Ksw";
import OneInch from "./icon/OneInch";
import Pancake from "./icon/Pancake";
import Paraswap from "./icon/Paraswap";
import Uniswap from "./icon/Uniswap";
import ZeroX from "./icon/ZeroX";

interface IProps {
    value: string;
    original: IOrder;
    className?: string;
}

const ExchangeCell: React.FC<IProps> = ({ value, original, className }) => {
    const exchanges = value.split(",");
    const noText = exchanges.length > 1;
    return (
        <div className="d-flex">
            {exchanges.map((v) => {
                switch (v.toLowerCase()) {
                    case "paraswap":
                        return <Paraswap noText={noText} />;
                    case "zerox":
                        return <ZeroX noText={noText} />;
                    case "zerox_rfqv3":
                    case "zerox_rfq_v3":
                    case "zeroxv3":
                        return <ZeroX noText={noText} text={"_V3"} />;
                    case "hashflow":
                        return <Hashflow noText={noText} />;
                    case "kyberswap":
                        return <Ksw noText={noText} />;
                    case "kyberswaprfq":
                        return <Ksw noText={noText} text={"_RFQ"} />;
                    case "1inch":
                    case "1inchv6":
                        return <OneInch noText={noText} />;
                    case "hashflowv3":
                        return <Hashflow noText={noText} />;
                    case "bebop":
                        return <Bebop noText={noText} />;
                    case "pcsx":
                    case "pancakeswap":
                        return <Pancake noText={noText} />;

                    case "uniswapx":
                        return <Uniswap noText={noText} />;
                    case "cow-solver":
                        return <Cow noText={noText} />;

                    default:
                        return <div> {value}</div>;
                }
            })}
        </div>
    );
};

export default ExchangeCell;
